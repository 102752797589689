import {
  sortByDistance,
  addDistance,
  addExactDistance,
  sortByExactDistance,
} from '../../tools';

const dealerState = {
  bounds: null,
  dealers: [],
  visibleMarkers: [],
  activeDealer: null,
  hoveredDealer: null,
  position: null,
  pinnedAddress: null,
  geoLocation: false,
  infoWindowPosition: undefined,
  warning: null,
  listIsAutoScrolling: false,
};

const actions = {
  setBounds({ commit, state }, bounds) {
    commit('setBounds', bounds);

    const visibleMarkers = state.dealers.filter(dealer => {
      if (dealer && dealer.location) {
        return bounds.contains({
          lat: dealer.location.lat,
          lng: dealer.location.lng,
        });
      }
      return false;
    });
    commit('setVisibleMarkers', visibleMarkers);
  },

  setDealers({ commit }, dealers) {
    commit(
      'setDealers',
      dealers.map(dealer => {
        if (dealer.latitude && dealer.latitude) {
          const location = {
            lat: parseFloat(dealer.latitude),
            lng: parseFloat(dealer.longitude),
          };
          return {
            ...dealer,
            location,
          };
        }
        return dealer;
      }),
    );
  },

  setActiveDealer({ commit }, value) {
    commit('setActiveDealer', value);
  },

  setHoveredDealer({ commit }, value) {
    commit('setHoveredDealer', value);
  },

  unsetHoveredDealer({ commit }) {
    commit('unsetHoveredDealer');
  },

  setPosition({ commit }, value) {
    commit('setPosition', value);
  },

  setGeoLocation({ commit }, value) {
    commit('setGeoLocation', value);
  },

  setWarning({ commit }, value) {
    commit('setWarning', value);
  },

  setPinnedAddress({ commit, state }, pinnedAddress) {
    if (pinnedAddress) {
      const address = {
        ...pinnedAddress,
        geometry: {
          location: {
            lat: pinnedAddress.geometry.location.lat(),
            lng: pinnedAddress.geometry.location.lng(),
          },
        },
        bounds: pinnedAddress.geometry.viewport,
      };

      commit('setPinnedAddress', address);
      const roughlyReorderedDealers = state.dealers
        .filter(dealer => dealer.location)
        .map(dealer => addDistance(dealer, pinnedAddress))
        .sort(sortByDistance);

      commit('setDealers', roughlyReorderedDealers);

      /**
       * Calculate the exact distance with Google Directions for the first five dealers.
       */
      const offset = 5;
      const promises = state.dealers
        .slice(0, offset)
        .filter(dealer => dealer.location)
        .map(dealer => addExactDistance(dealer, pinnedAddress));

      Promise.all(promises)
        .then(dealers => {
          const preciselyReordedDealers = dealers.sort(sortByExactDistance);
          const reorderedDealers = [
            ...preciselyReordedDealers,
            ...roughlyReorderedDealers.slice(offset),
          ];

          commit('setDealers', reorderedDealers);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      commit('setPinnedAddress', null);
    }
  },

  setVisibleMarkers({ commit }, value) {
    commit('setVisibleMarkers', value);
  },

  setInfoWindowPosition({ commit }, value) {
    commit('setInfoWindowPosition', value);
  },

  closeInfoWindow({ commit }) {
    commit('setActiveDealer', null);
    commit('setInfoWindowPosition', null);
  },

  setListIsAutoScrolling({ commit }, value) {
    commit('setListIsAutoScrolling', value);
  },
};

const mutations = {
  setBounds(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.bounds = value;
  },

  setDealers(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.dealers = value;
  },

  setVisibleMarkers(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.visibleMarkers = value;
  },

  setActiveDealer(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.activeDealer = value;
  },

  setHoveredDealer(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.hoveredDealer = value;
  },

  unsetHoveredDealer(state) {
    // eslint-disable-next-line no-param-reassign
    state.hoveredDealer = null;
  },

  setPosition(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.position = value;
  },

  setGeoLocation(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.geoLocation = value;
  },

  setWarning(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.warning = value;
  },

  setPinnedAddress(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.pinnedAddress = value;
  },

  setInfoWindowPosition(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.infoWindowPosition = value;
  },

  setListIsAutoScrolling(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.listIsAutoScrolling = value;
  },
};

const getters = {
  nearestDealer(state) {
    if (!state.pinnedAddress) {
      return null;
    }

    return state.dealers[0];
  },
};

export default { state: dealerState, actions, mutations, getters };
