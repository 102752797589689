const headerState = {
  onTop: true,
};

const actions = {
  setOnTop({ commit }, payload) {
    commit('setOnTop', payload);
  },
};

const mutations = {
  setOnTop(state, onTop) {
    state.onTop = onTop;
  },
};

export default { state: headerState, actions, mutations };
