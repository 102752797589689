const handler = (zustand, fieldset) => {
  const hideFieldset = zustand.value === 'Neuware, noch nicht montiert';

  fieldset.toggleAttribute('disabled', hideFieldset);
};

const initForms = () => {
  const form = document.querySelector('form[data-handle="maxam"]');

  if (form) {
    /**
     * Hide endverbraucher fieldset
     */
    const fieldset = form.querySelector('[data-endverbraucher]');
    const zustandInputs = Array.from(
      form.querySelectorAll('input[name="zustand"]'),
    );

    zustandInputs.forEach(zustand => {
      if (zustand.checked) {
        handler(zustand, fieldset);
      }

      zustand.addEventListener('change', () => {
        handler(zustand, fieldset);
      });
    });

    /**
     * Handle tyre selection
     */
    const typeOfMachine = form.querySelector('select[name="maschinentyp"]');

    if (typeOfMachine) {
      typeOfMachine.addEventListener('change', () => {
        window.MyMessageBus.fire('message', {
          typeOfMachine: typeOfMachine.value,
        });
      });
    }

    /**
     * Handle preselected fields
     */
    const search = new URLSearchParams(document.location.search);
    if (search.has('category')) {
      window.history.replaceState(
        {},
        '',
        window.location.href.replace(/\?.*/, ''),
      );
    }

    const syncSuffixElems = Array.from(
      form.querySelectorAll('[data-sync-suffix]'),
    );

    syncSuffixElems.forEach(elem => {
      const suffixInputs = Array.from(
        elem.querySelectorAll('input[type="radio"]'),
      );
      const suffixSpan = elem.querySelector('.suffix');

      suffixInputs.forEach(input => {
        input.addEventListener('input', () => {
          suffixSpan.innerText = input.value;
        });
      });
    });
  }
};

export default initForms;
