const formState = {
  season: null,
};

const actions = {
  setSeason({ commit }, season) {
    commit('setSeason', season);
  },
};

const mutations = {
  setSeason(state, season) {
    state.season = season;
  },
};

export default { state: formState, actions, mutations };
