const sync = (source, target) => {
  let event;
  /* eslint-disable-next-line no-param-reassign */
  target.value = source.value;
  if (typeof Event === 'function') {
    event = new Event('input');
  } else {
    event = document.createEvent('Event');
    event.initEvent('input', true, true);
  }

  // Trigger event for hidden inputs.
  target.dispatchEvent(event);
};

const filterInputs = input =>
  input.type !== 'hidden' && input.type !== 'checkbox';

const link = {
  bind(el, binding) {
    const checkbox = el.querySelector('input[type="checkbox"]');
    let changeHandler;

    if (checkbox) {
      checkbox.addEventListener('change', ({ target }) => {
        // Chrome has a bug where the select-component is not in DOM when this function triggers.
        // So let's use a Promise to check if the source element is available.
        const getSource = new Promise(resolve => {
          const interval = setInterval(() => {
            const element = document.querySelector(`#${binding.value}`);
            if (element) {
              clearInterval(interval);
              resolve(element);
            }
          }, 100);
        });

        getSource.then(source => {
          const targetInputs = Array.from(el.querySelectorAll('input')).filter(
            filterInputs,
          );

          const targetSelect = el.querySelector('select');
          const linked = el.querySelector('.linked');
          const width = linked.clientWidth;
          let height = linked.clientHeight;
          let sourceInputs;
          let sourceSelect;

          if (targetInputs.length) {
            sourceInputs = Array.from(source.querySelectorAll('input')).filter(
              filterInputs,
            );
          }
          if (targetSelect) {
            sourceSelect = source;
          }

          if (window.innerWidth >= 1248) {
            linked.style.width = `${width}px`;
          } else {
            linked.style.height = `${height}px`;
          }

          if (target.checked) {
            // Hide element
            setTimeout(() => {
              if (window.innerWidth >= 1248) {
                linked.style.width = 0;
              } else {
                linked.style.height = 0;
              }
              linked.inert = true;
            }, 10);

            if (targetInputs.length) {
              changeHandler = [];
              setTimeout(() => {
                sourceInputs.forEach((sourceInput, index) => {
                  const targetInput = targetInputs[index];
                  changeHandler.push(sync.bind(this, sourceInput, targetInput));
                  sourceInput.addEventListener('change', changeHandler[index]);
                  sync(sourceInput, targetInput);
                });
              }, 250);
            }

            if (targetSelect) {
              changeHandler = sync.bind(this, sourceSelect, targetSelect);
              sourceSelect.addEventListener('change', changeHandler);

              setTimeout(() => {
                sync(sourceSelect, targetSelect);
              }, 250);
            }
          } else {
            linked.inert = false;
            if (targetInputs.length) {
              sourceInputs.forEach((sourceInput, index) => {
                sourceInput.removeEventListener('change', changeHandler[index]);
              });
            }

            if (targetSelect) {
              sourceSelect.removeEventListener('change', changeHandler);
            }

            if (window.innerWidth >= 1248) {
              linked.style.width = null;
            } else {
              linked.style.height = null;
              height = `${linked.clientHeight}px`;
              linked.style.height = 0;

              setTimeout(() => {
                linked.style.height = height;
              }, 150);
            }
          }
        });
      });

      // Initially check the checkbox.
      setTimeout(() => {
        let event;

        if (typeof Event === 'function') {
          event = new Event('change');
        } else {
          event = document.createEvent('Event');
          event.initEvent('change', true, true);
        }

        checkbox.dispatchEvent(event);
      }, 10);
    }
  },
};

export default link;
