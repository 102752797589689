/* eslint-disable no-param-reassign */
const tyreState = {
  vehicleTypeOptions: [],
  seasonOptions: [],
  widthOptions: [],
  heightOptions: [],
  diameterOptions: [],
  vehicleType: null,
  season: null,
  width: null,
  height: null,
  diameter: null,
  tyreGroups: [],
};

const actions = {
  setVehicleTypeOptions({ commit }, vehicleTypeOptions) {
    commit('setVehicleTypeOptions', vehicleTypeOptions);
  },

  setSeasonOptions({ commit }, seasonOptions) {
    commit('setSeasonOptions', seasonOptions);
  },

  setWidthOptions({ commit }, widthOptions) {
    commit('setWidthOptions', widthOptions);
  },

  setHeightOptions({ commit }, heightOptions) {
    commit('setHeightOptions', heightOptions);
  },

  setDiameterOptions({ commit }, diameterOptions) {
    commit('setDiameterOptions', diameterOptions);
  },

  setVehicleType({ commit }, vehicleType) {
    commit('setVehicleType', vehicleType);
  },

  setSeason({ commit }, season) {
    commit('setSeason', season);
  },

  setWidth({ commit }, width) {
    commit('setWidth', width);
  },

  setHeight({ commit }, height) {
    commit('setHeight', height);
  },

  setDiameter({ commit }, diameter) {
    commit('setDiameter', diameter);
  },

  setTyreGroups({ commit }, tyreGroups) {
    commit('setTyreGroups', tyreGroups);
  },

  resetFilters({ commit }) {
    commit('setWidth', null);
    commit('setHeight', null);
    commit('setDiameter', null);
  },
};

const mutations = {
  setVehicleTypeOptions(state, vehicleTypeOptions) {
    state.vehicleTypeOptions = vehicleTypeOptions;
  },

  setSeasonOptions(state, seasonOptions) {
    state.seasonOptions = seasonOptions;
  },

  setWidthOptions(state, widthOptions) {
    state.widthOptions = widthOptions;
  },

  setHeightOptions(state, heightOptions) {
    state.heightOptions = heightOptions;
  },

  setDiameterOptions(state, diameterOptions) {
    state.diameterOptions = diameterOptions;
  },

  setVehicleType(state, vehicleType) {
    if (vehicleType === null) {
      state.vehicleType = null;
    }

    if (
      state.vehicleTypeOptions.some(
        vehicleTypeOption => vehicleTypeOption.slug === vehicleType,
      )
    ) {
      state.vehicleType = vehicleType;
    }
  },

  setSeason(state, season) {
    if (season === null) {
      state.season = null;
    }

    if (
      state.seasonOptions.some(seasonOption => seasonOption.slug === season)
    ) {
      state.season = season;
    }
  },

  setWidth(state, width) {
    if (state.widthOptions.includes(width) || !width) {
      state.width = width;
    }
  },

  setHeight(state, height) {
    if (state.heightOptions.includes(height) || !height) {
      state.height = height;
    }
  },

  setDiameter(state, diameter) {
    if (state.diameterOptions.includes(diameter) || !diameter) {
      state.diameter = diameter;
    }
  },

  setTyreGroups(state, tyreGroups) {
    state.tyreGroups = tyreGroups;
  },
};

const getters = {
  visibleTyreGroups(state) {
    const filteredTyreGroups = state.tyreGroups
      .filter(tyreGroup => {
        if (state.vehicleType) {
          return tyreGroup.vehicleType.some(
            vehicleType => vehicleType.slug === state.vehicleType,
          );
        }
        return true;
      })
      .filter(tyreGroup => {
        if (state.season) {
          return tyreGroup.season.some(
            season =>
              season.slug === state.season ||
              season.slug === 'ganzjahresreifen',
          );
        }
        return true;
      })
      .filter(tyreGroup => {
        if (state.width) {
          return tyreGroup.widths.some(width => width === state.width);
        }
        return true;
      })
      .filter(tyreGroup => {
        if (state.height) {
          return tyreGroup.heights.some(height => height === state.height);
        }
        return true;
      })
      .filter(tyreGroup => {
        if (state.diameter) {
          return tyreGroup.diameters.some(
            diameter => diameter === state.diameter,
          );
        }
        return true;
      });

    // Sort tyreGroups if season is selected. Place Ganzjahresreifen last.
    return state.season
      ? filteredTyreGroups.sort((a, b) => {
          const aIsAllyear = a.season.some(
            season => season.slug === 'ganzjahresreifen',
          );
          const bIsAllyear = b.season.some(
            season => season.slug === 'ganzjahresreifen',
          );
          let result = 0;

          if (aIsAllyear && bIsAllyear) {
            result = 0;
          }

          if (aIsAllyear) {
            result = 1;
          }
          if (bIsAllyear) {
            result = -1;
          }
          return result;
        })
      : filteredTyreGroups;
  },
};

export default { state: tyreState, actions, mutations, getters };
