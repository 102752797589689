import Vue from 'vue';
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
import VueAutosize from 'vue-autosize';
import { directive as onClickaway } from 'vue-clickaway';
import VueMq from 'vue-mq';
import 'wicg-inert';

import initForms from './forms';
import initHeadroom from './header';
import initNavigation from './navigation';
import store from './store';
import { convertTailwindScreensToVueMqBreakpoints } from './tools';

import autotab from './directives/autotab';
import input from './directives/input';
import link from './directives/link';
import video from './directives/video';

import lazyLoadComponent from '../utils/lazy-load-component';
import SkeletonBox from './components/SkeletonBox.vue';

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://34b243d76a3c4557ad6a7e65a4eb1201@sentry.urbantrout.io/3',
//     release: APP_VERSION,
//     logErrors: process.env.NODE_ENV !== 'production',
//     integrations: [
//       new Integrations.BrowserTracing({
//         tracingOrigins: [
//           'localhost',
//           'www.lassa.at',
//           'www.sailun.at',
//           'goldgarantie.autoplus-reifen.at',
//           'www.veerubber.at',
//         ],
//       }),
//     ],
//     tracesSampleRate: 0.2,
//   });
// }

const showConsentManager = () => {
  const barOverlay = document.getElementById('ppms_cm_bar_overlay');
  const popupOverlay = document.getElementById('ppms_cm_popup_overlay');

  if (barOverlay) {
    barOverlay.classList.add('show');
  }
  if (popupOverlay) {
    popupOverlay.classList.add('show');
  }

  document.documentElement.removeEventListener('mousemove', showConsentManager);
  document.documentElement.removeEventListener('click', showConsentManager);
  document.documentElement.removeEventListener('scroll', showConsentManager);
};

document.documentElement.addEventListener('mousemove', showConsentManager);
document.documentElement.addEventListener('click', showConsentManager);
document.documentElement.addEventListener('scroll', showConsentManager);

setTimeout(() => {
  showConsentManager();
}, 5000);

if (document.getElementById('app')) {
  const breakpoints = convertTailwindScreensToVueMqBreakpoints({
    md: '768px',
    lg: '1248px',
  });

  Vue.directive('on-clickaway', onClickaway);
  Vue.directive('link', link);
  Vue.directive('video', video);
  Vue.directive('input', input);
  Vue.directive('autotab', autotab);

  Vue.use(VueAutosize);
  Vue.use(VueMq, {
    breakpoints,
  });

  window.MyMessageBus = new (class {
    constructor() {
      this.vue = new Vue();
    }

    fire(event, data = null) {
      this.vue.$emit(event, data);
    }

    listen(event, callback) {
      this.vue.$on(event, callback);
    }
  })();

  // eslint-disable-next-line
  new Vue({
    el: '#app',
    store,
    components: {
      DealerSearchComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'DealerSearch' */ './components/DealerSearchComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      DealerSearchButton: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'Maps' */ './components/DealerSearchButton.vue'
          ),
        loading: SkeletonBox,
        loadingData: {
          props: {
            width: '195px',
            height: '40px',
          },
        },
      }),
      DealerListComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'DealerList' */ './components/DealerListComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      MapsComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'Maps' */ './components/MapsComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      TyreSearchComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'TyreSearch' */ './components/TyreSearchComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      TyreSelectionComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'TyreSelection' */ './components/TyreSelectionComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      TyreGroupComponent: () =>
        import(
          /* webpackChunkName: 'TyreGroup' */ './components/TyreGroupComponent.vue'
        ),
      TyreGroupListComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'TyreGroupList' */ './components/TyreGroupListComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      AdsComponent: lazyLoadComponent({
        componentFactory: () =>
          import(/* webpackChunkName: 'Ads' */ './components/AdsComponent.vue'),
        loading: SkeletonBox,
      }),
      AttachmentComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'Attachment' */ './components/AttachmentComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      FormComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'Form' */ './components/FormComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      SelectComponent: () =>
        import(
          /* webpackChunkName: 'Select' */ './components/SelectComponent.vue'
        ),
      LexiconLinksComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'LexiconLinks' */ './components/LexiconLinksComponent.vue'
          ),
        loading: SkeletonBox,
        loadingData: {
          props: { height: '110px' },
        },
      }),
      SeasonOptionsComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackChunkName: 'SeasonOptions' */ './components/SeasonOptionsComponent.vue'
          ),
        loading: SkeletonBox,
      }),
      ModalComponent: () =>
        import(
          /* webpackChunkName: 'Modal' */ './components/ModalComponent.vue'
        ),
      Popper: lazyLoadComponent({
        componentFactory: () =>
          import(/* webpackChunkName: 'popper' */ 'vue-popperjs'),
        loading: SkeletonBox,
      }),
      NotificationComponent: () =>
        import(
          /* webpackChunkName: 'Notification' */ './components/NotificationComponent.vue'
        ),
    },

    mounted() {
      const swipers = Array.from(
        document.querySelectorAll('.swiper-container'),
      );

      Array.from(this.$el.querySelectorAll('input[type="email"]')).forEach(
        i => {
          if (i.value !== i.defaultValue) {
            // eslint-disable-next-line no-param-reassign
            i.value = i.defaultValue;
          }
        },
      );

      if (swipers.length) {
        if (!('IntersectionObserver' in window)) {
          import(/* webpackChunkName: "swiper" */ './swiper.js').then(mod => {
            mod.default(swipers);
          });
        } else {
          const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              // Use `intersectionRatio` because of Edge 15's
              // lack of support for `isIntersecting`.
              // See: https://github.com/w3c/IntersectionObserver/issues/211
              if (entry.intersectionRatio <= 0) return;

              // Cleanup the observer when it's not
              // needed anymore.
              observer.unobserve(entry.target);
              // The `componentFactory()` resolves
              // to the result of a dynamic `import()`
              // which is passed to the `resolveComponent()`
              // function.

              import(/* webpackChunkName: "swiper" */ './swiper.js').then(
                mod => {
                  mod.default([entry.target]);
                },
              );
            });
          });

          setTimeout(() => {
            swipers.forEach(swiper => {
              observer.observe(swiper);
            });
          }, 400);
        }
      }
      initHeadroom();
      initNavigation();
      setTimeout(() => {
        initForms();
      }, 100);
    },
  });
}

if (module.hot) {
  module.hot.accept();
}
