export default {
  bind(el) {
    el.addEventListener('input', ({ target }) => {
      const maxlength = parseInt(target.getAttribute('maxlength'), 10);

      if (target.value.length === maxlength) {
        let element = target.nextElementSibling;
        while (element) {
          if (element.matches('input[type="text"]')) {
            break;
          }
          element = element.nextElementSibling;
        }
        if (element) {
          element.select();
        }
      }
    });
  },
};
