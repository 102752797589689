import Vue from 'vue';
import Vuex from 'vuex';
import dealers from './modules/dealers';
import tyres from './modules/tyres';
import form from './modules/form';
import header from './modules/header';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    dealers,
    tyres,
    form,
    header,
  },
});
