// Element.closest polyfill (https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Browser_compatibility)

if (!Element.prototype.matches)
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

function initNavigation() {
  const headroomElement = document.getElementById('header');
  const navigationModal = document.querySelector('#navigationModal');
  const navigationToggles = Array.from(
    document.querySelectorAll('[data-navigation-toggle]'),
  );

  function clickOutside(event) {
    if (!event.target.closest('#navigationModalInner')) {
      event.preventDefault();
      closeNavigationModal();
    }
  }

  function closeNavigationModal() {
    document.removeEventListener('click', clickOutside);
    navigationModal.classList.add('opacity-0');
    navigationModal.classList.remove('opacity-100');

    if (typeof headroom !== 'undefined') {
      headroom.init();
    }

    setTimeout(() => {
      navigationModal.classList.remove('flex');
      navigationModal.classList.add('hidden');
    }, 300);

    headroomElement.classList.remove('headroom--unpinned');
    headroomElement.classList.add('headroom--top');
    headroomElement.classList.add('headroom--pinned');
  }

  function openNavigationModal() {
    navigationModal.classList.add('opacity-0');
    navigationModal.classList.remove('hidden');
    navigationModal.classList.add('flex');

    setTimeout(() => {
      document.addEventListener('click', clickOutside);
      navigationModal.classList.remove('opacity-0');
      navigationModal.classList.add('opacity-100');

      if (typeof headroom !== 'undefined') {
        headroom.destroy();
      }

      headroomElement.classList.add('headroom--unpinned');
    }, 300);

    headroomElement.classList.remove('headroom--pinned');
    headroomElement.classList.add('headroom--unpinned');
  }

  function toggleNavigationModal() {
    if (navigationModal.classList.contains('hidden')) {
      openNavigationModal();
    } else {
      closeNavigationModal();
    }
  }

  navigationToggles.forEach(navigationToggle => {
    navigationToggle.addEventListener('click', () => {
      toggleNavigationModal();

      // toggle inert for a11y
      if (navigationModal.hasAttribute('inert')) {
        navigationModal.removeAttribute('inert');
      } else {
        navigationModal.setAttribute('inert', 'inert');
      }
    });
  });

  window.addEventListener('keyup', event => {
    // Esc key
    if (event.keyCode === 27) {
      closeNavigationModal();
      navigationModal.setAttribute('inert', 'inert');
    }
  });
}

export default initNavigation;
