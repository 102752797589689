const input = {
  bind(el, binding) {
    const inputs = Array.from(el.querySelectorAll('input'));
    const children = Array.from(el.children).filter(
      child => child.tagName !== 'LABEL',
    );
    inputs.forEach(i => {
      i.removeAttribute('name');
    });
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = binding.value;

    const updateHiddenInput = () => {
      if (inputs.every(i => !i.value)) {
        hiddenInput.value = '';
        return;
      }
      hiddenInput.value = children
        .map(child => {
          if (child.tagName.toUpperCase() === 'INPUT') {
            return child.value;
          }
          return child.textContent;
        })
        .join(' ');
    };

    updateHiddenInput();

    inputs.forEach(inputItem => {
      inputItem.addEventListener('input', () => {
        updateHiddenInput();
      });
    });
    el.appendChild(hiddenInput);
  },
};

export default input;
