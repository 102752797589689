import Headroom from 'headroom.js';
import store from './store';

const initHeadroom = () => {
  const header = document.querySelector('[data-headroom]');

  if (header) {
    const headroom = new Headroom(header, {
      offset: 0,
      tolerance: {
        up: 0,
        down: 20,
      },
      classes: {
        // when element is initialised
        initial: 'headroom',
        // when scrolling up
        pinned: 'headroom--pinned',
        // when scrolling down
        unpinned: 'headroom--unpinned',
      },
      onUnpin() {
        store.commit('setOnTop', false);
      },
      onTop() {
        store.commit('setOnTop', true);
      },
    });

    headroom.init();

    window.headroom = headroom;
  }
};

export default initHeadroom;
