const video = {
  bind(el, binding, vnode) {
    el.addEventListener('click', event => {
      event.preventDefault();
      vnode.context.$modal.show('youtube', { videoId: binding.value });
    });
  },
};

export default video;
