import haversine from 'haversine';

const loadGoogle = () =>
  new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (typeof google !== 'undefined') {
        clearInterval(interval);
        resolve();
      }
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      reject();
    }, 10000);
  });

export { loadGoogle };

const distance = ({ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }) => {
  const start = {
    latitude: lat1,
    longitude: lng1,
  };
  const end = {
    latitude: typeof lat2 === 'function' ? lat2() : lat2,
    longitude: typeof lng2 === 'function' ? lng2() : lng2,
  };
  return haversine(start, end);
};

export { distance };

const addDistance = (dealer, pinnedAddress) => ({
  ...dealer,
  distance: distance(dealer.location, pinnedAddress.geometry.location),
});

export { addDistance };

const addExactDistance = (
  dealer,
  pinnedAddress,
  checkForExistingDistance = false,
) =>
  new Promise((resolve, reject) => {
    if (checkForExistingDistance && dealer.exactDistance) {
      resolve(dealer);
    }

    const lat =
      typeof pinnedAddress.geometry.location.lat === 'function'
        ? pinnedAddress.geometry.location.lat()
        : pinnedAddress.geometry.location.lat;
    const lng =
      typeof pinnedAddress.geometry.location.lng === 'function'
        ? pinnedAddress.geometry.location.lng()
        : pinnedAddress.geometry.location.lng;

    loadGoogle().then(() => {
      const directionsService = new google.maps.DirectionsService();
      const request = {
        origin: new google.maps.LatLng(
          dealer.location.lat,
          dealer.location.lng,
        ),
        destination: new google.maps.LatLng(lat, lng),
        travelMode: 'DRIVING',
      };

      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          const exactDistance = result.routes[0].legs[0].distance;
          resolve({ ...dealer, exactDistance });
        }
        reject(new Error(status));
      });
    });
  });

export { addExactDistance };

const sortByDistance = (a, b) => a.distance - b.distance;

export { sortByDistance };

const sortByExactDistance = (a, b) =>
  a.exactDistance.value - b.exactDistance.value;

export { sortByExactDistance };

const convertTailwindScreensToVueMqBreakpoints = (
  tailwindScreens,
  smallestScreen = 'sm',
) => {
  const screens = Object.entries(tailwindScreens).map(screen => ({
    [screen[0]]: parseInt(screen[1], 10),
  }));

  const screenNames = [
    smallestScreen,
    ...screens.map(screen => Object.keys(screen).pop()),
  ];
  const screenSizes = [...screens.map(screen => Object.values(screen).pop())];

  return screenNames
    .map((name, index) => ({
      [name]: screenSizes[index] !== undefined ? screenSizes[index] : Infinity,
    }))
    .reduce(
      (accumulator, current) => ({
        ...accumulator,
        [Object.keys(current).pop()]: Object.values(current).pop(),
      }),
      {},
    );
};

export { convertTailwindScreensToVueMqBreakpoints };
